import React from 'react'
import {Button, Grid} from '@material-ui/core'

function GiveMore(props: any) {
  return (
    <React.Fragment>
      <Grid item xs={12}>
        <h4>Thanks for giving me money!</h4>
      </Grid>
      <Grid item xs={12}>
        <Button variant="outlined" onClick={props.onClick}>Give more money</Button>
      </Grid>
    </React.Fragment>
  )
}

export default GiveMore
