import React, {useState} from 'react'
import {ButtonStylingOptions, PayPalButton, PaypalOptions} from 'react-paypal-button'
import {Box, Button, FormControl, MenuItem, Select} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'

const useStyles = makeStyles({
  formControl: {
    marginBottom: '2rem'
  }
})

export default function PayPal(props: any) {

  const classes = useStyles()
  const [amount, setAmount] = useState(1.00)
  const [showPayment, setShowPayment] = useState(false)

  const paypalOptions: PaypalOptions = {
    clientId: props.clientId,
    currency: 'EUR',
    intent: 'capture'
  }

  const buttonStyles: ButtonStylingOptions = {
    color: 'white',
    shape: 'rect'
  }

  const handleChange = (event: any) => {
    setAmount(event.target.value)
  }

  const handleClick = (event: any) => {
    setShowPayment(true)
  }

  return (
    <React.Fragment>
      {
        !showPayment ? <Box m={1}><FormControl className={classes.formControl}>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={amount}
            onChange={handleChange}
          >
            <MenuItem value={1.00}>€1</MenuItem>
            <MenuItem value={5.00}>€5</MenuItem>
            <MenuItem value={10.00}>€10</MenuItem>
            <MenuItem value={20.00}>€20</MenuItem>
          </Select>
        </FormControl></Box> : <Box m={1}><strong>€{amount}</strong></Box>
      }

      {
        showPayment ? <Box m={1}><PayPalButton
          paypalOptions={paypalOptions}
          buttonStyles={buttonStyles}
          onPaymentSuccess={props.onPaymentSuccess}
          amount={amount}
        /></Box> : <Button variant="outlined" onClick={handleClick}>Give</Button>
      }

    </React.Fragment>
  )
}
