import React, {useState} from 'react'
import './App.css'
import PayPal from './PayPal'
import GiveMore from './GiveMore'
import {Box, Card, CardContent, Container, Grid} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'

const useStyles = makeStyles({
  mainContainer: {
    marginTop: '2rem'
  }
})

function App() {

  const classes = useStyles()

  const [showButtons, setShowButtons] = useState(true)

  const clientId = 'AQv1OrEF8-RVPvXUIvpK-fIxVAvZfFYGp3YePOnB7484mXP7_EZhrE7IqKN6V8anZ0TKr-Y_KomMylGC'

  const onPaymentSuccess = () => {
    setShowButtons(false)
  }

  const onGiveMoreClicked = () => setShowButtons(true)

  return (
    <Container maxWidth="sm" className={classes.mainContainer}>
      <Card>
        <CardContent>
          <Grid container justify="flex-start" direction="column" alignItems="center">
            <Grid item xs={12}>
              <h1>Gimme euros</h1>
            </Grid>
            <Grid item xs={10}>
              <Box textAlign="center"><h4>I'm tired of working...</h4></Box>
            </Grid>
            {
              showButtons ? (
                <PayPal
                  onPaymentSuccess={onPaymentSuccess}
                  clientId={clientId}
                />
              ) : <GiveMore onClick={onGiveMoreClicked}>thanks</GiveMore>
            }
          </Grid>
        </CardContent>
      </Card>
    </Container>
  )
}

export default App
